<template>
  <div>
    <a-space v-if="single">
      <a-button @click="visible = true" :type="buttonType">
        {{ record.invoiceCode ? record.invoiceCode : "选择" }}
      </a-button>
      <a-button
        icon="delete"
        :disabled="!record.invoiceCode"
        @click="deleteRecord"
      >
      </a-button>
    </a-space>
    <a-button v-else @click="visible = true" :type="buttonType">
      选择
    </a-button>

    <a-modal
      title="选择发票"
      :visible="visible"
      @cancel="cancel"
      :footer="null"
    >
      <a-input-search
        placeholder="请输入发票号码搜索"
        enter-button
        @search="onSearch"
      ></a-input-search>

      <Padding />

      <a-table
        bordered
        :data-source="list"
        :loading="loading"
        @change="onChange"
        :pagination="{
          total,
          current,
          pageSize,
          showTotal: (total) => `共 ${total} 条记录`,
        }"
        :rowClassName="() => 'clickable'"
        :customRow="
          (record) => {
            return {
              on: {
                click: () => {
                  onTableRowClick(record);
                },
              },
            };
          }
        "
      >
        <a-table-column title="发票号码" data-index="invoiceCode" />
        <a-table-column title="发票金额(元)" data-index="totalPriceLowercase" />
        <a-table-column title="税点" data-index="taxRate" align="right">
          <template slot-scope="taxRate">{{ taxRate }}%</template>
        </a-table-column>
      </a-table>
    </a-modal>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  props: {
    single: {
      type: Boolean,
      default: true,
    },
    buttonType: {
      type: String,
      default: "default",
    },
    // 选中的记录
    record: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      visible: false,
    };
  },

  computed: {
    ...mapState("invoiceSelector", [
      "loading",
      "current",
      "pageSize",
      "list",
      "total",
    ]),
  },

  watch: {
    visible(newValue) {
      if (newValue) {
        if (this.list.length === 0) {
          this.getList();
        }
      }
    },
  },

  methods: {
    ...mapMutations("invoiceSelector", [
      "setCurrent",
      "setPageSize",
      "setSearchValue",
    ]),
    ...mapActions("invoiceSelector", ["getList"]),

    onChange(pagination) {
      this.setCurrent(pagination.current);
      this.setPageSize(pagination.pageSize);
      this.getList();
    },

    onSearch(value) {
      this.setSearchValue(value);
      this.setCurrent(1);
      this.getList();
    },

    onTableRowClick(record) {
      this.$emit("getValue", record);
      this.visible = false;
    },

    deleteRecord() {
      this.$emit("getValue", {});
    },

    cancel() {
      this.visible = false;
    },
  },
};
</script>