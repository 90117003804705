var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.single)?_c('a-space',[_c('a-button',{attrs:{"type":_vm.buttonType},on:{"click":function($event){_vm.visible = true}}},[_vm._v(" "+_vm._s(_vm.record.invoiceCode ? _vm.record.invoiceCode : "选择")+" ")]),_c('a-button',{attrs:{"icon":"delete","disabled":!_vm.record.invoiceCode},on:{"click":_vm.deleteRecord}})],1):_c('a-button',{attrs:{"type":_vm.buttonType},on:{"click":function($event){_vm.visible = true}}},[_vm._v(" 选择 ")]),_c('a-modal',{attrs:{"title":"选择发票","visible":_vm.visible,"footer":null},on:{"cancel":_vm.cancel}},[_c('a-input-search',{attrs:{"placeholder":"请输入发票号码搜索","enter-button":""},on:{"search":_vm.onSearch}}),_c('Padding'),_c('a-table',{attrs:{"bordered":"","data-source":_vm.list,"loading":_vm.loading,"pagination":{
        total: _vm.total,
        current: _vm.current,
        pageSize: _vm.pageSize,
        showTotal: (total) => `共 ${total} 条记录`,
      },"rowClassName":() => 'clickable',"customRow":(record) => {
          return {
            on: {
              click: () => {
                _vm.onTableRowClick(record);
              },
            },
          };
        }},on:{"change":_vm.onChange}},[_c('a-table-column',{attrs:{"title":"发票号码","data-index":"invoiceCode"}}),_c('a-table-column',{attrs:{"title":"发票金额(元)","data-index":"totalPriceLowercase"}}),_c('a-table-column',{attrs:{"title":"税点","data-index":"taxRate","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(taxRate){return [_vm._v(_vm._s(taxRate)+"%")]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }